import './siteheader.scss';
import { dispatchForCode } from '../../utilities/js/helper';

class SiteHeader {
    constructor (element) {
        this.$siteheader = element;
        this.activeScrollNav = false;
        this.lastScrollPos = 0;
        this.scrollDistance = 250;
        this.scrollTimer = 0;
        this.scrollInterval = null;
        this.scrollMaxTimer = 1000;
        this.siteheaderEventTimer = null;
        this.bookmarkToggle = 'bookmarks-toggle';
        this.menuToggle = 'menu-toggle';
        this.$bookmarkToggle = this.$siteheader.querySelector('[for="' + this.bookmarkToggle + '"]');
        this.$menuToggle = this.$siteheader.querySelector('[for="' + this.menuToggle + '"]');
        this.$bookmarkCheckbox = this.$siteheader.querySelector('[id="' + this.bookmarkToggle + '"]');
        this.$menuCheckbox = this.$siteheader.querySelector('[id="' + this.menuToggle + '"]');
    }

    initialize () {
        this.checkPosition(() => {
            this.activeScrollNav = true;
        });

        this.setEvents();
    }

    setEvents () {
        document.addEventListener('keydown', (e) => {
            if (this.$siteheader.classList.contains('has--menu-open')) {
                dispatchForCode(e, (code) => {
                    if (code === 'Escape') {
                        if (this.$menuCheckbox.checked) {
                            this.$menuToggle.click();
                        }

                        if (this.$bookmarkCheckbox.checked) {
                            this.$bookmarkToggle.click();
                        }
                    }
                });
            }
        });
    }

    checkPosition (callback) {
        window.eventScroller.customFunctions.push(() => {
            if (this.activeScrollNav) {
                if (this.lastScrollPos !== 0) {
                    this.lastScrollPos = 0;
                }

                if (window.scrollDirection === 'up') {
                    clearInterval(this.scrollInterval);

                    if (this.lastScrollPos === 0) {
                        this.lastScrollPos = window.scrollPosition;
                    }

                    if (window.scrollPosition === 0) {
                        this.$siteheader.classList.remove('is--scrolled');
                        this.$siteheader.classList.remove('has--bg');
                    }

                    if ((window.scrollPosition > this.scrollDistance) || ((this.lastScrollPos - window.scrollPosition) >= (this.scrollDistance / 2))) {
                        if (!this.$siteheader.classList.contains('is--disabled-for-filter')) {
                            const visibleEvent = new CustomEvent('siteheaderVisible', {
                                view: window,
                                bubbles: true,
                                cancelable: false
                            });
                            this.$siteheader.dispatchEvent(visibleEvent);
                        }
                    }
                }

                if (window.scrollDirection === 'down') {
                    if (this.lastScrollPos !== 0) {
                        this.lastScrollPos = 0;
                    }

                    if (window.scrollPosition > 0) {
                        this.$siteheader.classList.add('is--scrolled');
                        this.$siteheader.classList.add('has--bg');
                    }
                }

                if (window.scrollPosition === 0) {
                    this.$siteheader.classList.remove('is--scrolled');
                    this.$siteheader.classList.remove('has--bg');
                }
            }
        });

        if (typeof callback === 'function' && !this.activeScrollNav) {
            if (window.scrollPosition > 0) {
                this.$siteheader.classList.add('has--bg');
                this.$siteheader.classList.add('is--scrolled');
            }

            setTimeout(() => {
                callback();
            }, 200);
        }
    }
}

export { SiteHeader };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $header = $context.querySelector('[data-header="root"]');
        if ($header) {
            const header = new SiteHeader($header);
            header.initialize();
            $header.headerAPI = header;
        }
    }
});
